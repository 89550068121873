import { Section, Grid, Spacing, Wrap, Lead, Title } from '@etchteam/mobius';

import HomeIllustration from '@/components/content/HomeIllustration/HomeIllustration';
import PageTitle from '@/components/content/PageTitle/PageTitle';

const IEBanner = () => {
  return (
    <>
      <PageTitle title="Internet Explorer is no longer supported" />
      <Section>
        <Wrap>
          {/* Collapsing margin wtf */}
          &nbsp;
          <Spacing top="fluid" bottom="lg">
            <Grid wrap>
              <Grid.Item xs={12} xl={6}>
                <Spacing bottom="lg">
                  <Spacing bottom="md">
                    <Title>Internet Explorer is no longer supported</Title>
                  </Spacing>
                  <Lead>
                    <p>The LA Portal doesn&apos;t support your browser.</p>
                    <p>
                      For the best experience, we recommend switching to a
                      modern browser such as{' '}
                      <a
                        href="https://www.microsoft.com/en-us/edge"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Microsoft Edge
                      </a>{' '}
                      or{' '}
                      <a
                        href="https://www.google.com/chrome/index.html"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Google Chrome
                      </a>
                    </p>
                  </Lead>
                </Spacing>
              </Grid.Item>
              <Grid.Item xs={12} xl={6}>
                <HomeIllustration />
              </Grid.Item>
            </Grid>
          </Spacing>
          {/* Collapsing margin wtf */}
          &nbsp;
        </Wrap>
      </Section>
    </>
  );
};

export default IEBanner;
