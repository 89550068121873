import { useRef } from 'react';
import {
  useQuery as urqlUseQuery,
  useMutation,
  UseQueryState,
  OperationContext,
  OperationResult,
} from 'urql';

import { useGlobalErrors } from '@/components/composition/ErrorBoundary/ErrorBoundary';
import { CURRENT_USER, LOCAL_AUTHORITY } from '@/lib/queries';
import { Preference } from '@/types/preference.type';
import { User } from '@/types/user.type';

const UPDATE_USER_PREFERENCE = `
  mutation updateUserPreference($key: String!, $value: JSON!) {
    updateUserPreference(key: $key, value: $value) {
      id
      key
      value
    }
  }
`;

// Run once before render
export const useComponentWillMount = (func) => {
  const willMount = useRef(true);

  if (willMount.current) {
    func();
  }

  willMount.current = false;
};

// Grab the current user
export const useUser = (): [
  User,
  (opts?: Partial<OperationContext>) => void,
  boolean,
] => {
  const [{ data, fetching }, refetchUser] = urqlUseQuery<{
    currentUser?: User;
  }>({
    query: CURRENT_USER,
  });

  const user: User = data?.currentUser?.id ? data.currentUser : ({} as User);

  return [user, refetchUser, fetching];
};

// Manage user preferences
export const usePreferences = (): [
  { [key: string]: any },
  (
    variables?: Preference,
    context?: Partial<OperationContext>,
  ) => Promise<OperationResult>,
  () => void,
  boolean,
] => {
  const [{ preferences }, refetchUser, fetching] = useUser();
  const setPreference = useMutation(UPDATE_USER_PREFERENCE)[1];

  // remap preferences as an object
  const result = {} as { [key: string]: any };
  (preferences || []).forEach((p) => {
    result[p.key] = p.value;
  });

  return [result, setPreference, refetchUser, fetching];
};

// Your LA
// Manage user preferences
export const useYourLA = (): {
  id: number; // The LA's core information id
  slug: string;
  name: string;
  fetching: boolean;
} => {
  const [preferences] = usePreferences();
  const [{ data, fetching }] = urqlUseQuery({
    query: LOCAL_AUTHORITY,
    variables: {
      slug: preferences.localAuthority,
    },
    pause: !preferences.localAuthority,
  });

  const la = data?.localAuthority;

  return la
    ? {
        ...la,
        fetching,
      }
    : {};
};

// Enhanced version of urql useQuery
// Sets an error state in the error boundary if we get a 404
export const useQuery = (args) => {
  const [result, refetch] = urqlUseQuery(args);
  const { onError } = useGlobalErrors();

  const message = (result?.error?.message || '').toLowerCase();

  if (message.includes('not found')) {
    onError({
      code: '404',
    });
  }

  return [result, refetch] as [
    UseQueryState<any, object>,
    (opts?: Partial<OperationContext>) => void,
  ];
};
