import { NextPageContext } from 'next';
import NextErrorComponent, { ErrorProps } from 'next/error';
import Image from 'next/image';

import PageTitle from '@/components/content/PageTitle/PageTitle';

import styles from './ErrorPage.module.scss';

interface ErrorPageProps extends ErrorProps {
  message?: string;
  hasGetInitialPropsRun?: boolean;
}

declare function _getInitialProps({
  res,
  err,
}: NextPageContext): Promise<ErrorPageProps> | ErrorPageProps;

class ErrorPage extends NextErrorComponent<ErrorPageProps> {
  static getInitialProps: typeof _getInitialProps;

  fallbackMessage() {
    const { message, statusCode } = this.props;

    switch (statusCode) {
      case 401:
        return 'Please login to continue';
      case 403:
        return "Sorry, you don't have permission to access this page";
      case 404:
        return (
          message ?? 'Sorry, the page you’re looking for could not be found'
        );
      default:
        return 'Oops, something has gone wrong! Our developers have been notified and will look into it right away';
    }
  }

  render() {
    const { title = 'This is rubbish!', statusCode } = this.props;

    return (
      <>
        <PageTitle title={title} />
        <div className={styles['error-page']}>
          <div className={styles['error-page__content']}>
            {statusCode && (
              <span className={styles['error-page__code']}>{statusCode}</span>
            )}
            <h1 className={styles['error-page__title']}>{title}</h1>
            <p className={styles['error-page__message']}>
              {this.fallbackMessage()}
            </p>
            <Image
              className={styles['error-page__img']}
              src="/uk/images/error/rubbish.svg"
              alt="A pile of actual rubbish"
              width={437}
              height={179}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ErrorPage;
