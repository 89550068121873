// Logs LAs as recently viewed in the user prefs
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import { useComponentWillMount, usePreferences } from '@/lib/hooks';

function createRecentList(current = [], path) {
  if (!current.includes(path)) {
    current.unshift(path);
  }

  return current.slice(0, 5);
}

export default function RecentlyViewedLog() {
  const router = useRouter();
  const [preferences, setPreference] = usePreferences();
  const on = router.events?.on;
  const off = router.events?.off;
  const recents = JSON.stringify(preferences.recent);
  const { slug } = router.query;

  const updateRecentList = useCallback(() => {
    // If we have a slug, it's probably an LA
    if (slug) {
      // Just check it looks like a slug too
      // we had a few assets getting caught as slugs
      const laSlugRegex = /^[0-9a-zA-Z-]+$/;

      if (laSlugRegex.test(slug as string)) {
        const recentArray = recents ? JSON.parse(recents) : [];
        setPreference({
          key: 'recent',
          value: createRecentList(recentArray, slug),
        });
      }
    }
  }, [slug, preferences, setPreference]);

  useComponentWillMount(updateRecentList);

  useEffect(() => {
    on('routeChangeComplete', updateRecentList);

    return () => {
      off('routeChangeComplete', updateRecentList);
    };
  }, [on, off, router.pathname, updateRecentList]);

  return null;
}
